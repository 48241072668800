<template>
  <div class="vote">
    <div class="vote-title">添加投票</div>
    <div class="vote_main">
      <van-form ref="voteForm">
        <van-field
          v-model="voteArr.title"
          minlength="2"
          maxlength="15"
          :rules="[
            { required: true, message: '请填写投票主题' },
            { validator, message: '长度在 2 到 15 个字符' }
          ]"
          placeholder="请输入投票主题 (2~15字)"
        />
        <div class="vote_main_cont">
          <p class="vote_title">选项（最多20个选项，每项限15字）</p>
          <ul class="vote_titleList">
            <li v-for="(item, index) in voteArr.titleList" :key="item.id">
              <van-field
                class="van-field"
                v-model="item.name"
                maxlength="15"
                :rules="[{ required: true, message: '请填写选项' }]"
                :placeholder="`选项${index + 1}`"
              />
              <span
                v-show="voteArr.titleList.length > 2"
                class="right_c"
                @click.prevent="right_c(item)"
              ></span>
            </li>
            <li class="vote_tit" @click.prevent="addButon">
              <span class="vote_titIon"></span
              ><span
            >添加选项 (剩余可添加{{
                20 - voteArr.titleList.length
              }}项)</span
            >
            </li>
          </ul>
        </div>
      </van-form>

      <div class="vote_type">
        <p class="vote_title">投票类型</p>
        <div class="vote_select_btn">
          <span
            :class="curr === index ? 'active_span' : ''"
            v-for="(item, index) in voteOptions"
            :key="index"
            @click="currClick(item, index)"
          >{{ item.name }}</span
          >
        </div>

        <div
          @click.prevent="van_zdkxx"
          v-show="isMultipleChoiceShow"
        >
          <van-cell
            title="最大可选项"
            :value="qzTypeVal ? qzTypeVal : '请选择'"
            is-link
          />
        </div>
        <van-action-sheet
          v-model="kxzTypeShow"
          :actions="actionsShe"
          cancel-text="取消"
          closeable
          close-on-click-action
          @select="handleSelecKkz"
        />
        <p class="split-line"></p>
        <div @click.prevent="handleEndTime">
          <van-cell
            title="投票截止日期"
            :value="time ? time : '请选择'"
            is-link
          />
        </div>
        <van-popup v-model="VanDateShow" position="bottom">
          <!-- 时间选择器 -->
          <van-datetime-picker
            v-model="currentDate"
            @confirm="confirm"
            @cancel="cancel"
            type="datetime"
            title="选择年月日"
            :min-date="minDate"
            :max-date="maxDate"
          />
        </van-popup>
      </div>

      <div class="vote_bottom">
        <div class="vote_btn" @click.prevent="addVote">添加投票</div>
      </div>
      <!-- <van-action-sheet
          v-model="showActionShe"
              :actions="actionsShe"
              cancel-text="取消"
              close-on-click-action
              @select="handleSelectShe"
        /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'vote', // 添加投票
  props: {
    editVoteData: {
      type: Object,
      default: () => {
      }
    }
  },
  data () {
    return {
      voteArr: {
        titleList: [{id: 0}, {id: 1}],
        title: '',
        type: 0
      },
      voteOptions: [
        {name: '单选', type: 0},
        {name: '多选', type: 1}
      ],
      // columns: [
      //   2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
      // ],
      // actionsShe: [{ name: '2' }, { name: '3' }, { name: '4' }, { name: '5' }],
      curr: 0,
      qzTypeVal: '',
      tpjzrq: '',
      ids: 2,
      kxzTypeShow: false,
      showActionShe: false,
      VanDateShow: false,
      minDate: new Date(new Date().getTime() + 1200000),
      maxDate: new Date(2030, 10, 1),
      currentDate: new Date(),
      currentTru: true, // 开始时间-结束时间控制器
      interactionType: '',
      hideRemove: false,
      plActive: 0,
      isMultipleChoiceShow: false,
      time: ''
    }
  },
  computed: {
    actionsShe () {
      const arr = []
      this.voteArr.titleList.forEach((item, index) => {
        if (index + 1 >= 2) {
          arr.push({
            name: index + 1
          })
        }
        console.log(item)
      })
      return arr
    }
  },
  created () {
    console.log(this.editVoteData)
    const editVoteData = JSON.parse(JSON.stringify(this.editVoteData))
    if (editVoteData && Object.keys(editVoteData).length > 0) {
      this.voteArr = editVoteData
      this.currentDate = new Date(editVoteData.time)
      this.time = editVoteData.time
      console.log(this.time)
      this.curr = editVoteData.type
    }
  },
  methods: {
    // 编辑时数据回显[暂时使用不销毁组件的方式]
    editVote () {
      console.log(1111)
    },
    validator (val) {
      if (val.length >= 2 && val.length <= 15) {
        return true
      } else {
        return false
      }
    },
    timeFormat (time) {
      console.log(time)
      // 时间格式化 2019-09-08
      let year = time.getFullYear()
      let month = time.getMonth() + 1
      let newMonth = month < 10 ? '0' + month : month

      let day = time.getDate() < 10 ? '0' + time.getDate() : time.getDate()
      let hours = time.getHours() < 10 ? '0' + time.getHours() : time.getHours()
      let minutes =
        time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()
      let seconds =
        time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds()
      this.time =
        year +
        '-' +
        newMonth +
        '-' +
        day +
        ' ' +
        hours +
        ':' +
        minutes +
        ':' +
        seconds
      return year + '年' + newMonth + '月' + day + '日'
    },
    currClick (item, index) {
      this.voteArr.type = item.type
      this.curr = index
      if (index == 1) {
        this.isMultipleChoiceShow = true
        this.kxzTypeShow = true
      } else {
        this.isMultipleChoiceShow = false
      }
    },
    van_zdkxx () {
      this.kxzTypeShow = true
    },
    // 多选
    onConfirm (v) {
      this.qzTypeVal = v
      this.kxzTypeShow = false
    },
    // 最大可选项
    handleSelecKkz (v) {
      this.qzTypeVal = v.name
      this.kxzTypeShow = false
      this.voteArr.changMore = v.name
    },
    onCancel () {
      this.kxzTypeShow = false
    },
    // 投票截止日期
    handleEndTime () {
      this.VanDateShow = true
      this.currentTru = false
    },
    // 确定提交投票
    async addVote () {
      try {
        await this.$refs.voteForm.validate()
        if (this.voteArr.type === 1 && !this.voteArr.changMore) {
          return this.$toast({
            message: '请选择最大可选项',
            position: 'top'
          })
        }
        if (!this.time) {
          return this.$toast({
            message: '请选择投票截止时间',
            position: 'top'
          })
        }
        this.voteArr.time = this.time
        this.$emit('handleVote', this.voteArr)
      } catch (e) {
        if (Array.isArray(e) && e.length > 0) {
          return this.$toast({
            message: e[0].message,
            position: 'top'
          })
        }
      }
    },
    // 减按钮
    right_c (item) {
      this.$dialog
        .confirm({
          title: '移除选项',
          confirmButtonText: '确定',
          cancelButtonText: '再考虑下',
          message: '确定移除当前选项?'
        })
        .then(() => {
          this.voteArr.titleList.forEach((v, i) => {
            if (item.id === v.id) {
              this.voteArr.titleList.splice(i, 1)
            }
          })
        })
    },

    // 时间选择确认
    confirm (v) {
      this.VanDateShow = false
      this.voteArr.tpjzrq = this.timeFormat(v)
    },
    // 取消
    cancel () {
      this.VanDateShow = false
    },
    // 添加选项
    addButon () {
      if (this.voteArr.titleList.length >= 20) return
      this.voteArr.titleList.push({
        id: this.ids++
      })
      console.log(this.voteArr.titleList)
      console.log(this.ids)
    },
    handleLoadMore () {
    },
    handleReplyComment (item) {
      console.log(item)
    },
    handleCommentTouch (item, index) {
      console.log(item, index)
    },
    handleCommentTouchend () {
    },
    handleDeleteComment () {
    },
    handleCommentLike () {
    },
    handleToUserDetail () {
    }
  }
}
</script>

<style lang="scss" scoped>
.vote {
  .vote-title {
    line-height: 57.41px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $titleColor;
    font-size: 18px;
    text-align: center;
  }

  .vote_main {
    margin-top: 16px;
    margin-bottom: 100px;
    width: 375px;
    background: #ffffff;

    ::v-deep .van-cell {
      background: transparent;
    }

    .vote_title {
      margin: 17.5px 0 10px 0;
    }

    .split-line {
      height: 5px;
      background: #f4f4f4;
    }

    .vote_main_cont {
      font-size: 14px;
      color: #6d7278;
      line-height: 18px;
      padding: 0 20px;

      .vote_titleList {
        & > li {
          padding: 0 11px;
          margin-bottom: 10px;
          background: #f8f8f8;
          border: 1px solid #e5e5e5;
          border-radius: 4px;
          line-height: 38px;
          position: relative;

          ::v-deep .van-cell {
            padding: 5px 10px;
            background: transparent;
          }
        }

        .right_c {
          width: 14px;
          height: 14px;
          position: absolute;
          right: 11px;
          top: 35%;
          display: inline-block;
          background: url('~@/assets/imgs/circle/Slice__48.svg');
          background-repeat: no-repeat;
        }

        .vote_tit {
          text-align: center;
          color: #fe5800;

          .vote_titIon {
            width: 14px;
            height: 14px;
            margin-right: 5px;
            position: relative;
            top: 2px;
            display: inline-block;
            background: url('~@/assets/imgs/circle/Slice_jia.svg');
            background-size: cover;
          }
        }
      }
    }

    .vote_type {
      width: 375px;
      background: #ffffff;

      .vote_title {
        font-size: 14px;
        padding-top: 10px;
        margin-left: 20px;
        color: #6d7278;
        line-height: 18px;
      }

      .vote_select_btn {
        font-size: 14px;
        color: #6d7278;
        line-height: 31px;
        margin: 0 21px;
        padding-bottom: 19px;
        border-bottom: 1px solid #f4f4f4;

        span {
          display: inline-block;
          width: 81px;
          height: 32px;
          text-align: center;
          background: #f6f6f6;
          border-radius: 88px;
          margin-right: 15px;
        }

        .active_span {
          display: inline-block;
          height: 32px;
          background: #fc6601;
          color: #fff;
          border-radius: 88px;
        }
      }
    }

    .vote_bottom {
      position: fixed;
      bottom: 0;
      height: 79px;
      width: 100%;
      padding: 19px 0 0;
      background: #fff;

      .vote_btn {
        width: 335px;
        height: 48px;
        margin: 0 auto;
        background: #fe5800;
        border-radius: 200px;
        font-size: 16px;
        text-align: center;
        color: #ffffff;
        line-height: 46px;
      }
    }
  }
}
</style>
